<template>
  <b-container class="bg-white" fluid>
    <b-row class="mb-4">
      <b-col>
        <b-link :to="{ name: 'AppReporting' }">Back to data loading</b-link>
        <b-button variant="outline-primary" class="mb-2" @click="generateReport">download report</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container>
          <div id="reportExport">
            <div style="font-family: Arial; font-size: 12px">
              <h1 style="color: #00338D;">Controls testing</h1>
              <h2 style="color: #00338D; margin-top: 50px;">Summary - version 1</h2>
              <table style="font-family: Arial; font-size: 12px;">
                <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                  <td> </td>
                  <td colspan="5">Control instances</td>
                </tr>
                <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                  <td>Control theme</td>
                  <td>tested</td>
                  <td>without any findings</td>
                  <td>with design findings</td>
                  <td>tested for operating effectiveness</td>
                  <td>with OE findings</td>
                </tr>
                <tr v-for="component in controls" :key="component.component">
                  <td>{{component.component}}</td>
                  <td>{{component.statsControls}}</td>
                  <td>{{component.statsWithoutFindings}}</td>
                  <td>{{component.statsWithTodFindings}}</td>
                  <td>{{component.statsToeTested}}</td>
                  <td>{{component.statsToeFindings}}</td>
                </tr>
                <tr style="font-weight: 700;">
                  <td>TOTAL</td>
                  <td>{{stats.controls}}</td>
                  <td>{{stats.withoutFindings}}</td>
                  <td>{{stats.withTodFindings}}</td>
                  <td>{{stats.toeTested}}</td>
                  <td>{{stats.toeFindings}}</td>
                </tr>
              </table>
              <h2 style="color: #00338D; margin-top: 50px;">Summary - version 2</h2>
              <table style="font-family: Arial; font-size: 12px;">
                <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                  <td>Theme</td>
                  <td>Controls tested</td>
                  <td>Without design findings</td>
                  <td>With design findings</td>
                  <td>Tested for OE</td>
                  <td>Without OE findings</td>
                  <td>With OE findings</td>
                </tr>
                <tr v-for="component in controls" :key="component.component">
                  <td>{{component.component}}</td>
                  <td>{{component.statsControls}}</td>
                  <td>{{component.statsControls - component.statsWithTodFindings}}</td>
                  <td>{{component.statsWithTodFindings}}</td>
                  <td>{{component.statsToeTested}}</td>
                  <td>{{component.statsToeTested - component.statsToeFindings}}</td>
                  <td>{{component.statsToeFindings}}</td>
                </tr>
                <tr style="font-weight: 700;">
                  <td>TOTAL</td>
                  <td>{{stats.controls}}</td>
                  <td>{{stats.controls - stats.withTodFindings}}</td>
                  <td>{{stats.withTodFindings}}</td>
                  <td>{{stats.toeTested}}</td>
                  <td>{{stats.toeTested - stats.toeFindings}}</td>
                  <td>{{stats.toeFindings}}</td>
                </tr>
              </table>
              <div v-for="component in controls" :key="component.component">
                <h2 style="color: #00338D; margin-top: 50px;">{{component.component}}</h2>
                <div v-for="control in component.controls" :key="control.Key">
                  <div>
                    <table style="font-family: Arial; font-size: 12px; background-color: #00338D; color: white;" width=100%>
                       <tr>
                         <td style="background-color: #00338D; color: white; font-size: 14px; font-weight: 700; padding: 10px 5px 10px 5px;">
                           {{control['Control Instance ID']}}
                          </td>
                        </tr>
                    </table>
                    <br />
                    <div><strong>Objective</strong><br /><markdown :content="control['Objective']"></markdown></div>
                    <div><strong>Description</strong><markdown :content="control['Control Description']"></markdown></div>
                    <div><strong>Frequency:</strong> {{control['Frequency']}}</div>
                    <div><strong>Business area: </strong> {{control['Business Area']}}</div>
                    <div><strong>Location:</strong> {{control['Location']}}</div>
                    <div><strong>Testing approach:</strong> {{control['Testing Approach']}}</div>
                    <br />
                    <div>
                      <strong>Design assessment</strong>
                      <p v-if="control.tod.length === 0">No findings noted</p>
                      <div v-if="control.tod.length > 0">
                        <div v-for="issue in control.tod" :key="issue.key">
                          <div>
                            <br /><strong>{{issue['Summary']}}</strong>
                            <markdown :content="issue['Description']"></markdown>
                            <markdown :content="'**Management response:** ' + (issue['Additional Information'] + ' ').replace('Management response: ', '')"></markdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <strong>Operating effectiveness testing procedure</strong><br />
                      <markdown v-if="control['Procedure']" :content="control['Procedure']"></markdown>
                      <p v-if="!control['Procedure']">testing in progress</p>
                    </div>
                    <div v-if="control['Procedure'] && !control['Procedure'].startsWith('N/A')">
                      <strong>Operating effectiveness assessment</strong>
                      <p v-if="control.toe.length === 0">No findings noted</p>
                      <div v-if="control.toe.length > 0">
                        <div v-for="issue in control.toe" :key="issue.key">
                          <div>
                            <br /><strong>{{issue['Summary']}}</strong>
                            <markdown :content="issue['Description']"></markdown>
                            <markdown :content="'**Management response:** ' + (issue['Additional Information'] + ' ').replace('Management response: ', '')"></markdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br /><br />
                  </div>
                </div>
              </div>
              <h1 style="color: #00338D;">Appendix - Findings noted</h1>
              <h2 style="color: #00338D; margin-top: 50px;">Open CAPs</h2>
              <table style="border: 1px solid #00338D; border-collapse: collapse; font-family: Arial; font-size: 12px;">
                <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">MCRP 1/2</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">CAP no</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Name</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Completion date</td>
                </tr>
                <tr v-for="cap in caps" :key="'cap' + cap.number">
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{cap.MCRP}}</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{cap.number}}</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{cap.name}}</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{cap.date}}</td>
                </tr>
              </table>
              <h2 style="color: #00338D; margin-top: 50px;">Controls findings</h2>
              <table style="border: 1px solid #00338D; border-collapse: collapse; font-family: Arial; font-size: 12px;">
                <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Theme</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Control</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Finding</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Management response</td>
                </tr>
                <tr v-for="finding in findingsControls" :key="finding.key">
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{finding['Component/s']}}</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{finding['Control Instance ID']}}</td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;"><strong>{{finding['Summary']}}</strong><div><markdown :content="finding['Description']"></markdown></div></td>
                  <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;"><markdown :content="(finding['Additional Information'] + ' ').replace('Management response: ', '')"></markdown></td>
                </tr>
              </table>
              <div>
                <br /><br />
                <h1 style="color: #00338D;">Appendix - Scoping</h1>
                <h2 v-if="risks.length > 0" style="color: #00338D; margin-top: 50px;">Risk types</h2>
                <table v-if="risks.length > 0" style="border: 1px solid #00338D; border-collapse: collapse; font-family: Arial; font-size: 12px;">
                  <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Risk id</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Level 4 risk type</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Definition</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">RTC function</td>
                  </tr>
                  <tr v-for="(risk, i) in risks" :key="'risk' + i">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{risk.id}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{risk.type}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{risk.definition}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{risk.function}}</td>
                  </tr>
                </table>
                <h2 v-if="controlsscope.length > 0" style="color: #00338D; margin-top: 50px;">Controls scoping</h2>
                <table v-if="controlsscope.length > 0" style="border: 1px solid #00338D; border-collapse: collapse; font-family: Arial; font-size: 12px;">
                  <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Control id</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Control name</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Risk types relevant to the Order</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Scope categorization</td>
                  </tr>
                  <tr v-for="(item, i) in controlsscope" :key="'controlscoping' + i">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.id}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.name}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.risk}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.scope}}</td>
                  </tr>
                </table>
              </div>
              <div v-if="policies.length > 0">
                <br /><br />
                <h1 style="color: #00338D;">Appendix - Policies reviewed</h1>
                <table style="border: 1px solid #00338D; border-collapse: collapse; font-family: Arial; font-size: 12px;">
                  <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Name</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Owner</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Reviewed in ACR 2022</td>
                  </tr>
                  <tr v-for="(item, i) in policies" :key="'policy' + i">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.name}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.owner}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.reviewed}}</td>
                  </tr>
                </table>
              </div>
              <div v-if="procedures.length > 0">
                <br /><br />
                <h1 style="color: #00338D;">Appendix - Procedures reviewed</h1>
                <table style="border: 1px solid #00338D; border-collapse: collapse; font-family: Arial; font-size: 12px;">
                  <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Name</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Owner</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">Reviewed in ACR 2022</td>
                  </tr>
                  <tr v-for="(item, i) in procedures" :key="'policy' + i">
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.name}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.owner}}</td>
                    <td style="border: 1px solid #00338D; border-collapse: collapse; padding: 5px;">{{item.reviewed}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import Markdown from '@/components/Markdown.vue'
import config from './config.js'

export default {
  name: "ReportingAppMain",
  components: {
    Markdown
  },
  data () {
    return {
      caps: [],
      controls: [],
      controlsscope: [],
      findingsControls: [],
      policies: [],
      procedures: [],
      risks: [],
      stats: null
    }
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'Reporting app main', action: 'open Reporting app main page' })
    const recordsRaw = {}
    config.files.forEach(x => {
      recordsRaw[x.id] = JSON.parse(localStorage.getItem('frmcloudReportingApp' + x.id))
    })
    if (recordsRaw.caps) {
      this.caps = recordsRaw.caps
    }
    if (recordsRaw.controlsscope) {
      this.controlsscope = recordsRaw.controlsscope
    }
    if (recordsRaw.risks) {
      this.risks = recordsRaw.risks
    }
    if (recordsRaw.policies) {
      this.policies = recordsRaw.policies
    }
    if (recordsRaw.procedures) {
      this.procedures = recordsRaw.procedures
    }
    console.log(this.policies)
    const data = recordsRaw.main
    const componentsAll = []
    _.remove(data, x => {
      return x['Summary'].toUpperCase().match('NO DESIGN FINDING NOTED') || x['Summary'].toUpperCase().match('NO FINDINGS NOTED')
    })
    _.each(data, x => {
      if (x['Issue Type'] === 'Control') {
        componentsAll.push(x['Component/s'])
      }
      if (x['Finding Type'] === 'Design assessment (control)') {
        let summary = x['Summary']
        const space = summary.indexOf(' ')
        summary = summary.slice(space + 1)
        x['Summary'] = summary
      }
      if (x['Finding Type'] === 'Operating effectiveness assessment (control)') {
        let summary = x['Summary']
        const space = summary.indexOf(' ')
        summary = summary.slice(space + 1)
        x['Summary'] = summary
      }
    })

    // create findings table
    let findings = _.filter(data, x => {
      return x['Finding Type'] === 'Design assessment (control)'
    })
    findings = _.sortBy(findings, ['Component/s', 'Control Instance ID', 'Finding Type', 'Summary'])
    this.findingsControls = findings
    let findingsOe = _.filter(data, x => {
      return x['Finding Type'] === 'Operating effectiveness assessment (control)'
    })
    findingsOe = _.sortBy(findingsOe, ['Component/s', 'Control Instance ID', 'Finding Type', 'Summary'])
    this.findingsControls = findings.concat(findingsOe)

    const components = _.uniq(componentsAll)
    const controls = []
    _.each(data, x => {
      if (x['Issue Type'] === 'Control') {
        x.tod = []
        x.toe = []
        x.toeTested = !x['Procedure'].startsWith('N/A')
        controls.push(x)
      }
    })
    _.each(controls, control => {
      const tod = _.filter(data, x => {
        return x['Finding Type'] === 'Design assessment (control)' && x['Control Instance ID'] === control['Control Instance ID']
      })
      control.tod = tod
      const toe = _.filter(data, x => {
        return x['Finding Type'] === 'Operating effectiveness assessment (control)' && x['Control Instance ID'] === control['Control Instance ID']
      })
      control.toe = toe
    })
    const controlsByComponent = []
    _.each(components, x => {
      const row = {
        component: x,
        controls: []
      }
      controlsByComponent.push(row)
    })
    _.each(controlsByComponent, x => {
      const rows = _.filter(controls, y => {
        return x.component === y['Component/s']
      })
      x.controls = _.orderBy(rows, ['Control Number'])
    })
    const controlsByComponentSorted = _.sortBy(controlsByComponent, ['component'])
    _.each(controlsByComponentSorted, x => {
      x.statsControls = x.controls.length
      x.statsWithoutFindings = _.reduce(x.controls, function (accumulator, value, key) {
        const count = value.tod.length === 0 && value.toe.length === 0 ? 1 : 0
        return accumulator + count
      }, 0)
      x.statsWithTodFindings = _.reduce(x.controls, function (accumulator, value, key) {
        const count = value.tod.length > 0 ? 1 : 0
        return accumulator + count
      }, 0)
      x.statsToeTested = _.reduce(x.controls, function (accumulator, value, key) {
        const count = value.toeTested ? 1 : 0
        return accumulator + count
      }, 0)
      x.statsToeFindings = _.reduce(x.controls, function (accumulator, value, key) {
        const count = value.toe.length > 0 ? 1 : 0
        return accumulator + count
      }, 0)
    })
    const stats = {
      controls: _.reduce(controlsByComponentSorted, function (accumulator, value, key) {
        return accumulator + value.statsControls
      }, 0),
      withoutFindings: _.reduce(controlsByComponentSorted, function (accumulator, value, key) {
        return accumulator + value.statsWithoutFindings
      }, 0),
      withTodFindings: _.reduce(controlsByComponentSorted, function (accumulator, value, key) {
        return accumulator + value.statsWithTodFindings
      }, 0),
      toeTested: _.reduce(controlsByComponentSorted, function (accumulator, value, key) {
        return accumulator + value.statsToeTested
      }, 0),
      toeFindings: _.reduce(controlsByComponentSorted, function (accumulator, value, key) {
        return accumulator + value.statsToeFindings
      }, 0)
    }
    this.controls = controlsByComponentSorted
    this.stats = stats
  },
  methods: {
    generateReport: function () {
      const start = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Report</title></head><body>"
      const end = "</body></html>"
      const html = start + document.getElementById('reportExport').innerHTML + end
      const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html)
      const filename = 'report.doc'
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      link.click()
    }
  },
  watch: {
  }
}
</script>

<style>
</style>
